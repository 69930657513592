.Success {
    text-align: center;
    flex: 1 1 auto;
}

.Success .three-columns {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
}

