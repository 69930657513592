.App {
    height: 100vh;
    width: 100vw;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Form {
    display: flex;
    flex: 0 1 80%;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    min-height: 50vh;
}
.Form .message-container {
    height: 5rem;
    color: red;
    font-size: 2rem;
    text-align: center;
}
.Form input {
    display: block;
    border-radius: 0.25rem;
    font-size: 3rem;
    text-align: center;
    padding: 4px;
}

.Form button {
    color: #fff;
    background-color: #00b74a;
    font-size: 2rem;
}

